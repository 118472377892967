export const environment = {
    production: false,
    version: '0.102',
    apiKeyMap: 'AIzaSyDjfOjsuMXS2D_XuG3e3XFnVAufH7bBB6c',
    apiKeyMapMobile: 'AIzaSyBtSmp-pqjaer3Hfg3zdJE43z7J5EiCyGo',
    api: 'https://api-puma-dev-aws.tercerpiso.tech/api/',
    //api: 'http://localhost:3001/api/',
    //api: 'http://192.168.0.201:3001/api/',
    //ssoLocation: 'http://localhost:4201'
    ssoLocation: 'https://puma-websso-dev.tercerpiso.net',
    apiSSO: 'https://puma-sso-dev.tercerpiso.tech/api/',
    intervalMigratingRequest: 800,
    oldSimulationLink: 'https://ludela.tercerpiso.tech/HuellaCarbono/HuellaCarbonoReport/simulacion',
    oldMonitoreoLink: 'https://ludela.tercerpiso.tech/Reports/DynamicReport',
    grafanaDashboardProdClientUuid: 'https://grafana.tercerpiso.tech/d/f3bb8482-a5fe-4853-9305-cf9cf79909f1/dashboardprodclientuuid?orgId=1',
    grafanaDashboardProyecto: 'https://grafana.tercerpiso.tech/d/f3bb8482-a5fe-4853-9305-cf9cf79909f2/dashboardagroproyecto?orgId=1',
    grafanaDashboardRetaaProdClientUuid: 'https://grafana.plataformapuma.com/d/b826144e-ed15-4c7c-b6d8-50349597cb7r/comparativa-retaa-prodcli?orgId=1',
    grafanaDashboardRetaaProyecto: 'https://grafana.plataformapuma.com/d/b826144e-ed15-4c7c-b6d8-50349597cb8r/comparativa-retaa-proyecto?orgId=1',
    grafanaDashboardGanaderia: 'https://grafana.tercerpiso.tech/d/e9eb701b-9569-4411-bd31-8b19e5cafcbc/ganaderia?orgId=1&var-tipo=Tambo',
    showCleanData: true,
    mapServerURL: 'https://backend.plataformapuma.com:8183/cgi-bin/mapserv?',

    CO2: 1,
    CH4: 25,
    N2O: 298,
    platformApi: 'https://puma-monitoring-middleware-dev-aws.tercerpiso.tech/api/',
    timeoutWatchDogSatellite: 2000

  };
